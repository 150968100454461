import React, { useEffect, useState } from "react";
import "./App.css"; // Make sure your spinner styles are in this file
import {
	BrowserRouter as Router,
	Routes,
	Route,
	Navigate,
	useLocation,
} from "react-router-dom";
import { SignupLogin } from "./pages/SignupLogin/SignupLogin";
import { Dashboard } from "./pages/Dashboard/Dashboard";
import { ForgotPassword } from "./pages/forgotPassword/forgotPassword";
import { PasswordReset } from "./pages/passwordReset/passwordReset";
import { ActivateAccount } from "./pages/activate_account/ActivateAccount";
import { Navbar } from "./components/Navbar/Navbar";
import { Home } from "./components/Home/Home";
import { About } from "./components/About/About";
import { Features } from "./components/Features/Features";
import { Values } from "./components/Values/Values";
import { Footer } from "./components/Footer/Footer";
import { ManageAccount } from "./pages/ManageAccount/ManageAccount";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute"; // Import PrivateRoute
import axios from "axios";

function App() {
	const [isAuthenticated, setIsAuthenticated] = useState(false);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const validateToken = async () => {
			const token = localStorage.getItem("token");

			if (token) {
				try {
					const response = await axios.post(
						"https://advancedmathriddles.com/backend/includes/process/p-validate-token.php",
						{}, // Send an empty object as the body
						{
							headers: {
								Authorization: `Bearer ${token}`,
							},
						}
					);

					if (response.status === 200 && response.data.valid) {
						setIsAuthenticated(true);
					} else {
						setIsAuthenticated(false);
						localStorage.clear();
					}
				} catch (error) {
					console.error("Error during validating token:", error);
					setIsAuthenticated(false);
					localStorage.clear(); // Clear localStorage on error
				}
			} else {
				setIsAuthenticated(false);
			}
			setLoading(false);
		};
		validateToken();
	}, []);

	if (loading) {
		return (
			<div className="loading-container">
				<div className="loading-spinner"></div>
			</div>
		);
	}

	return (
		<Router>
			<ConditionalNavbar isAuthenticated={isAuthenticated} />
			<Routes>
				<Route
					path="/"
					element={
						isAuthenticated ? (
							<Navigate to="/dashboard" />
						) : (
							<>
								<Home />
								<About />
								<Features />
								<Values />
								<Footer />
							</>
						)
					}
				/>
				<Route
					path="/signupLogin"
					element={
						!isAuthenticated ? (
							<SignupLogin setIsAuthenticated={setIsAuthenticated} />
						) : (
							<Navigate to="/dashboard" />
						)
					}
				/>
				<Route
					path="/dashboard"
					element={
						isAuthenticated ? (
							<Dashboard setIsAuthenticated={setIsAuthenticated} />
						) : (
							<Navigate to="/signupLogin" />
						)
					}
				/>
				<Route
					path="/forgotPassword"
					element={
						!isAuthenticated ? (
							<ForgotPassword />
						) : (
							<Navigate to="/dashboard" />
						)
					}
				/>
				<Route
					path="/manageAccount"
					element={
						<PrivateRoute
							element={<ManageAccount />}
							isAuthenticated={isAuthenticated}
						/>
					} // Use PrivateRoute
				/>
				<Route path="/passwordReset/:token" element={<PasswordReset />} />
				<Route path="/activateAccount/:token" element={<ActivateAccount />} />
			</Routes>
		</Router>
	);
}

function ConditionalNavbar({ isAuthenticated }) {
	const location = useLocation();

	if (location.pathname === "/" && !isAuthenticated) {
		return <Navbar />;
	}
	return null;
}

export default App;
